<template lang="pug">
  div
    v-data-table(
      :items="accountCodeList"
      :headers="headers"
    )
      template(v-slot:item="{ item }")
        tr
          td {{ item.parent_code }}
          td {{ item.account_code }}
          td {{ item.description }}
</template>
<script>
import accountCodeRepository from '@/repositories/account-code.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [accountCodeGetVars, accountCodeGetVarNames] = requestVars({ identifier: 'account-code' })
const accountCodeGetHandler = new VueRequestHandler(null, accountCodeGetVarNames)

const tableVars = () => ({
  headers: [
    { text: 'Parent Code', value: 'parent' },
    { text: 'Account Code', value: 'code' },
    { text: 'Description', value: 'description' },
  ],
})

export default {
  name: 'AccountCode',
  data () {
    return {
      ...tableVars(),
      ...accountCodeGetVars,
    }
  },
  created () {
    this.getAccountCodes()
  },
  methods: {
    getAccountCodes () {
      const handler = accountCodeGetHandler
      const repository = accountCodeRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    initWebsockets () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
    },
    dbStoreEvent ({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (this.model === 'AccountCode') {
        this.accountCodeDBStoreEvent(data)
      }
    },
    accountCodeDBStoreEvent (data) {
      this.accountCodeList.push(data)
    },
  },
  mixins: [
    require('./mixin.js').default,
  ],
}
</script>